import {RouteLocationNormalized} from "vue-router";
import {apiClient} from "@/services/api-client";
import {Roles} from "@/enums/roles";
import {getIdentityFromJwt} from "@/helpers/user-identity";
import {App} from "vue";
import {errors} from "@/mixins/errors";

export const TOKEN_KEY = 'AUTH_TOKEN';
export const REFRESH_TOKEN_KEY = 'AUTH_REFRESH_TOKEN';

export const Auth = {


    publicPages: ['login'],
    defaultPageRouteName: 'texts-list',
    // @ts-ignore
    app,

    install (app: App): void {
        this.app = app;
        this.init();
    },

    async init() {
        try {
            await this.loginFromLocalStorage();
        } catch (error: Error|any) {
            if (!error.response || 401 !== error.response.status) {
                console.error('Error in Auth.init():', error);
            }
        }
    },

    async loginWithCredentials(username: string, password: string) {
        return await apiClient.post<LoginResponse>('/api/login', {username, password}).then(response => {
            localStorage.setItem('AUTH_TOKEN', response.data.token);
            localStorage.setItem('AUTH_REFRESH_TOKEN', response.data.refresh_token);

            this.login(response.data.token, response.data.refresh_token);
            this.redirectToDefaultPage();
        });
    },

    async loginFromLocalStorage() {
        let token = localStorage.getItem(TOKEN_KEY);
        let refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY);

        if (!token || !refreshToken) {
            return;
        }

        const identity = getIdentityFromJwt(token, refreshToken);

        let time = (new Date()).getTime();
        let expirationTime = this.getExpirationTime(identity.expires);

        if (time >= expirationTime) {
            let data;

            try {
                data = await this.refresh(refreshToken);
            } catch (error: Error|any) {
                if (!error.response || 401 !== error.response.status) {
                    console.error('Error in Auth.init():', error);
                }

                localStorage.removeItem(TOKEN_KEY);
                localStorage.removeItem(REFRESH_TOKEN_KEY);
                this.app.config.globalProperties.$router.push({name: 'login'})

                return;
            }

            token = data.token;
            refreshToken = data.refreshToken;
        }

        if (token && refreshToken) {
            this.login(token, refreshToken);
        }
    },

    login(token: string, refreshToken: string) {
        const identity = getIdentityFromJwt(token, refreshToken);

        identity.roles = Object.values(identity.roles);
        if (!identity.roles.some((r: string) => {
            return [Roles[Roles.ROLE_ADMIN], Roles[Roles.ROLE_EDITOR]].includes(r);
        })) {
            throw errors.MISSING_ROLE;
        }

        const time = (new Date()).getTime();
        const expirationTime = this.getExpirationTime(identity.expires);
        let timeout = expirationTime - time;

        if (timeout <= 0) {
            /**
             * Looks like the token has expiration time less than expected (less than previously or less than 1 minute).
             * @see Auth.getExpirationTime
             */
            timeout = 60000; // 1 minute is the smallest reasonable value.
        }

        this.app.config.globalProperties.$store.commit('user/login', identity);
    },

    redirectToDefaultPage() {
        const identity = getIdentityFromJwt(localStorage.getItem(TOKEN_KEY) ?? '', localStorage.getItem(REFRESH_TOKEN_KEY) ?? '');

        this.app.config.globalProperties.$router.push({name: Auth.defaultPageRouteName})
            .catch((error: Error) => {
                console.error('Error during redirection:', error);
            });
    },

    logout() {
        localStorage.removeItem(TOKEN_KEY);
        localStorage.removeItem(REFRESH_TOKEN_KEY);
        this.app.config.globalProperties.$store.commit('user/logout');
        this.app.config.globalProperties.$router.push({name: 'login'})
            .catch((error: Error) => {
                console.error('Error during redirection:', error);
            });
    },

    isAllowed(route: RouteLocationNormalized) {
        // @ts-ignore
        return !route.meta.roles || this.app.config.globalProperties.$store.state.user?.identity?.roles?.some(r => route.meta.roles.includes(r));
    },

    getExpirationTime(expires: number) {
        return (new Date(expires*1000 - 60000)).getTime();
    },

    async refresh(refreshToken: string) {
        const response = await apiClient.post('/api/token/refresh', {refresh_token: refreshToken});

        return {
            token: response.data.token,
            refreshToken: response.data.refreshToken,
        }
    }
}