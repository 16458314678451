import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "wrapper d-flex flex-column min-vh-100 bg-light dark:bg-transparent" }
const _hoisted_2 = { class: "body flex-grow-1 px-3" }
const _hoisted_3 = { class: "d-flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppSidebar = _resolveComponent("AppSidebar")!
  const _component_AppHeader = _resolveComponent("AppHeader")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_CContainer = _resolveComponent("CContainer")!
  const _component_AppFooter = _resolveComponent("AppFooter")!
  const _component_CToastBody = _resolveComponent("CToastBody")!
  const _component_CToastClose = _resolveComponent("CToastClose")!
  const _component_CToast = _resolveComponent("CToast")!
  const _component_CToaster = _resolveComponent("CToaster")!
  const _component_AppAside = _resolveComponent("AppAside")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_AppSidebar),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_AppHeader),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_CContainer, { lg: "" }, {
          default: _withCtx(() => [
            _createVNode(_component_router_view)
          ]),
          _: 1
        })
      ]),
      _createVNode(_component_AppFooter),
      _createVNode(_component_CToaster, {
        autohide: 3000,
        placement: "bottom-end"
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.fixedToasts, (toast, index) => {
            return (_openBlock(), _createBlock(_component_CToast, {
              key: toast,
              color: $options.toastColor(index),
              show: true
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_3, [
                  _createVNode(_component_CToastBody, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString($options.toastMessage(index)), 1)
                    ]),
                    _: 2
                  }, 1024),
                  _createVNode(_component_CToastClose, { class: "me-2 m-auto" })
                ])
              ]),
              _: 2
            }, 1032, ["color"]))
          }), 128))
        ]),
        _: 1
      })
    ]),
    _createVNode(_component_AppAside)
  ], 64))
}