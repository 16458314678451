
import { CContainer } from '@coreui/vue-pro'
import AppAside from '@/components/AppAside.vue'
import AppFooter from '@/components/AppFooter.vue'
import AppHeader from '@/components/AppHeader.vue'
import AppSidebar from '@/components/AppSidebar.vue'
import store from "@/store"
export default {
  name: 'DefaultLayout',
  components: {
    AppAside,
    AppFooter,
    AppHeader,
    AppSidebar,
    CContainer,
  },
  computed: {
    fixedToasts(): number {
      return store.state.fixedToasts
    },
  },
  methods: {
    toastColor(toast: number): string {
      // @ts-ignore
      return store.state.toasts[toast].color
    },
    toastMessage(toast: number): string {
      // @ts-ignore
      return store.state.toasts[toast].message
    }
  },
}
