import {getIdentityFromJwt} from "@/helpers/user-identity";
import {Auth, TOKEN_KEY} from "@/services/auth";

export class User {
    identity = {};
}

export class Identity {
    userId;
    token;
    refreshToken;
    expires;
    roles;

    constructor(userId: string, token: string, refreshToken: string, expires: number, roles: string[]) {
        this.userId = userId;
        this.token = token;
        this.refreshToken = refreshToken;
        this.expires = expires;
        this.roles = roles;
    }
}

export class UserModule {

    namespaced = true;
    state;

    mutations = {
        login(state: any, identity: Identity) {
            state.identity = identity;
        },
        logout(state: any) {
            state.identity = {};
        },
    };

    constructor() {
        const user = new User();
        const token = localStorage.getItem(TOKEN_KEY);

        if (token) {
            user.identity = getIdentityFromJwt(token);
        }

        this.state = user;
    }

}
