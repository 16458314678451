import {createRouter, createWebHashHistory, RouteRecordRaw} from 'vue-router'
import DefaultLayout from '@/layouts/DefaultLayout.vue'
import {Auth} from "@/services/auth";
import store from "@/store";
import {Roles} from "@/enums/roles";

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'Home',
        component: DefaultLayout,
        redirect: '/texts',
        meta: {
            label: 'Dashboard',
        },
        children: [
            {
                path: '/texts',
                name: 'texts-list',
                meta: {
                    label: 'Tekty',
                    authRequired: 'true',
                    roles: [Roles[Roles.ROLE_ADMIN], Roles[Roles.ROLE_EDITOR]]
                },
                component: () => import('@/views/Text/List.vue'),
            },
            {
                path: '/texts/:id',
                name: 'texts-details',
                meta: {
                    label: 'Edytuj tekst',
                    authRequired: 'true',
                    roles: [Roles[Roles.ROLE_ADMIN], Roles[Roles.ROLE_EDITOR]]
                },
                component: () => import('@/views/Text/Edit.vue'),
            },
            {
                path: '/synonyms',
                name: 'synonyms-list',
                meta: {
                    label: 'Synonimy',
                    authRequired: 'true',
                    roles: [Roles[Roles.ROLE_ADMIN], Roles[Roles.ROLE_EDITOR]]
                },
                component: () => import('@/views/Synonym/List.vue'),
            },
            {
                path: '/synonyms/:id',
                name: 'synonyms-details',
                meta: {
                    label: 'Edytuj synonim',
                    authRequired: 'true',
                    roles: [Roles[Roles.ROLE_ADMIN], Roles[Roles.ROLE_EDITOR]]
                },
                component: () => import('@/views/Synonym/Edit.vue'),
            },
            {
                path: '/users',
                name: 'users-list',
                meta: {
                    label: 'Użytkownicy',
                    authRequired: 'true',
                    roles: [Roles[Roles.ROLE_ADMIN]]
                },
                component: () => import('@/views/User/List.vue'),
            },
            {
                path: '/users/:id',
                name: 'users-details',
                meta: {
                    label: 'Edytuj użytkownika',
                    authRequired: 'true',
                    roles: [Roles[Roles.ROLE_ADMIN]]
                },
                component: () => import('@/views/User/Edit.vue'),
            },
        ]
    },
    {
        path: '/login',
        name: 'login',
        meta: {
            label: 'Logowanie',
            authRequired: 'false',
        },
        component: () => import('@/views/Login.vue'),
    },
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

router.beforeEach((to, from, next) => {
    const publicPages = Auth.publicPages;
    const authRequired = !publicPages.includes(to.path);

    if (!Auth.isAllowed(to)) {
        return next('/login');
    }

    // @ts-ignore
    if (!to.meta.authRequired && store.state.user?.identity?.userId) {
        return next('/texts');
    }

    next();
})

export default router
