import jwtDecode from "jwt-decode";
import {Identity} from "@/store/modules/User";

interface JwtToken {
    userId: string;
    exp: number;
    roles: string[];
}

export function getIdentityFromJwt(token: string, refreshToken?: string) {
    const jwt = jwtDecode(token) as JwtToken;
    return new Identity(jwt.userId, token, refreshToken ?? '', jwt.exp, jwt.roles);
}