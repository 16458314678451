import {Roles} from "@/enums/roles";

export default [
  {
    component: 'CNavItem',
    name: 'Teksty',
    to: '/texts',
    icon: 'cil-text',
    roles: [Roles[Roles.ROLE_ADMIN], Roles[Roles.ROLE_EDITOR]]
  },
  {
    component: 'CNavItem',
    name: 'Synonimy',
    to: '/synonyms',
    icon: 'cib-sublime-text',
    roles: [Roles[Roles.ROLE_ADMIN], Roles[Roles.ROLE_EDITOR]]
  },
  {
    component: 'CNavItem',
    name: 'Użytkownicy',
    to: '/users',
    icon: 'cis-user',
    roles: [Roles[Roles.ROLE_ADMIN]]
  },
]
