import axios from 'axios'
import store from "@/store"
import {errors} from "@/mixins/errors"

export const apiClient = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
})

apiClient.interceptors.request.use(
    config => {
        config.headers['Authorization'] = `Bearer ${localStorage.getItem('AUTH_TOKEN')}`;
        return config;
    },
    error => {
        return Promise.reject(error);
    }
)

apiClient.interceptors.response.use((response) => {
    return response
}, (error) => {
    if (typeof error.response === 'undefined') {
        delete apiClient.defaults.headers.common.Authorization
        localStorage.removeItem('AUTH_TOKEN')
        localStorage.removeItem('AUTH_REFRESH_TOKEN')
        store.commit('showErrorToast', errors.methods.getGenericError().message)
    }

    return Promise.reject(error)
})
