import { defineRule, configure } from 'vee-validate'
import { localize } from '@vee-validate/i18n'
import AllRules from '@vee-validate/rules'

Object.keys(AllRules).forEach(rule => {
    defineRule(rule, AllRules[rule])
})

defineRule('phone', (value: string) => {
    if (!value || !value.length) {
        return true
    }

    if (!/[0-9]{9}/.test(value)) {
        return 'Niepoprawny numer telefonu. Wpisz telefon w formie 9 cyfr.'
    }

    return true
})

defineRule('postCode', (value: string) => {
    if (!value || !value.length) {
        return true
    }

    if (!/[0-9]{2}-[0-9]{3}/.test(value)) {
        return 'Niepoprawny kod pocztowy. Przykład: 12-345. '
    }

    return true
})

configure({
    validateOnInput: true,
    generateMessage: localize('pl', {
        messages: {
            required: 'To pole jest wymagane',
            email: 'Niepoprawny adres e-mail',
        },
    }),
});