<template>
  <CHeader position="sticky" class="bg-primary mb-4">
    <CContainer fluid>
      <CHeaderToggler
        :class="[
          'px-md-0',
          'me-md-3',
          'd-lg-none',
          { 'prevent-hide': !sidebarVisible },
        ]"
        @click="$store.commit('toggleSidebar')"
      >
        <CIcon icon="cil-menu" size="lg" />
      </CHeaderToggler>
      <CHeaderBrand class="mx-auto d-md-none text-white" to="/">
        <CIcon :icon="logoNegative" height="34" alt="Logo" />
      </CHeaderBrand>
      <CHeaderNav class="ms-auto me-4">
        <CButtonGroup aria-label="Theme switch">
          <CFormCheck
            type="radio"
            :button="{ color: 'primary' }"
            name="theme-switch"
            id="btn-light-theme"
            autoComplete="off"
            :checked="$store.state.theme === 'default'"
            @change="
              (event) =>
                $store.commit({
                  type: 'toggleTheme',
                  value: 'default',
                })
            "
          >
            <template #label><CIcon icon="cil-sun" /></template>
          </CFormCheck>
          <CFormCheck
            type="radio"
            :button="{ color: 'primary' }"
            name="theme-switch"
            id="btn-dark-theme"
            autoComplete="off"
            :checked="$store.state.theme === 'dark'"
            @change="
              (event) =>
                $store.commit({
                  type: 'toggleTheme',
                  value: 'dark',
                })
            "
          >
            <template #label><CIcon icon="cil-moon" /></template>
          </CFormCheck>
        </CButtonGroup>
      </CHeaderNav>
    </CContainer>
  </CHeader>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import { logoNegative } from '@/assets/brand/logo-negative'

export default {
  name: 'AppHeader',
  components: {
  },
  setup() {
    const store = useStore()
    return {
      logoNegative,
      sidebarVisible: computed(() => store.state.sidebarVisible),
    }
  },
}
</script>
