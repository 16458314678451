
import {useStore} from "vuex";

export default {
  name: 'AppFooter',
  setup() {
    const store = useStore();

    return {
      appName: store.state.appName
    }
  }
};
