import {createApp} from 'vue'
import CoreuiVue from '@coreui/vue-pro'
import {CIcon} from '@coreui/icons-vue'
import App from './App.vue'
import router from './router'
import store from './store'

import {iconsSet as icons} from '@/assets/icons'
import {Auth} from "@/services/auth"
import { vue3Debounce } from 'vue-debounce'
import './helpers/validation-rules'
import moment from "moment/moment";

const app = createApp(App)

app.use(store)
app.use(router)
app.use(CoreuiVue)
app.use(Auth, {store: store, router: router})

app.provide('icons', icons)
app.component('CIcon', CIcon)
app.directive('debounce', vue3Debounce({ lock: true, defaultTime: 300 }))

app.config.globalProperties.$filters = {
    stripHTML(value: string) {
        const div = document.createElement('div')
        div.innerHTML = value
        return div.textContent || div.innerText || ''
    },
    formatDate(value: string) {
        return moment(String(value)).format('DD-MM-YYYY hh:mm')
    }
}

app.mount('#app')
