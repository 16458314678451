import {AxiosResponse} from "axios";

export const errors = {
    ACCESS_DENIED: {
        code: '85e7ddb3-577c-4e24-8267-295fd49c525e',
        message: 'Odmowa dostępu.'
    },
    BAD_CREDENTIALS: {
        code: '9f91ce90-6b60-47bd-9d8a-e9d4500d8476',
        message: 'Błędny login lub hasło.'
    },
    CURRENT_PASSWORD_WRONG: {
        code: '1f51678d-4f6d-4aa8-8c9f-bedec8007209',
        message: 'Podano nieprawidłowe stare hasło.'
    },
    JWT_TOKEN_EXPIRED: {
        code: '56b0066f-3919-4071-b8c7-ad4918188ea9',
        message: 'Uprawnienia wygasły. Zaloguj się ponownie.'
    },
    JWT_TOKEN_INVALID: {
        code: 'a22844cc-2f4a-4a16-a587-8cbe7a658527',
        message: 'Użytkownik niezautoryzowany.'
    },
    JWT_TOKEN_NOT_FOUND: {
        code: '7ae9d027-a435-4499-8c30-43cdfeee96e1',
        message: 'Brak tokena autoryzującego.'
    },

    // Internal errors.

    MISSING_ROLE: {
        code: 'missing_role',
        message: 'Odmowa dostępu (brak wymaganej roli).'
    },

    methods: {
        getGenericError() {
            return {
                message: 'Coś poszło nie tak. Spróbuj później.',
            };
        },
        resolveError: function (error: CustomError) {
            if (!(error instanceof Object) || !error.code) {
                return null; // Error not recognized - display the generic one.
            }

            let knownError: string;

            for (const knownErrorName in errors) {
                // @ts-ignore
                knownError = errors[knownErrorName];

                // @ts-ignore
                if (error.code === knownError.code) {
                    return knownError;
                }
            }

            return null;
        },
        resolveErrors(errors: CustomError[]) {
            let knownError, knownErrors = [];

            for (const error of errors) {
                knownError = this.resolveError(error);

                if (knownError) {
                    knownErrors.push(knownError);
                }
            }

            return knownErrors;
        },
        resolveErrorsFromResponse(response: AxiosResponse) {
            if (!response || !response.data || !response.data.errors) {
                return null; // Unable to resolve (not a response or empty error collection).
            }

            return this.resolveErrors(response.data.errors);
        },
        resolveException(e: any) {
            if (!e) {
                return null;
            }

            if (e.response) {
                return this.resolveErrorsFromResponse(e.response);
            }

            return [this.resolveError(e)];
        },
    }
}