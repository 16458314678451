import { createStore } from 'vuex';
import {UserModule} from "@/store/modules/User";

export default createStore({
  state: {
    asideVisible: false,
    sidebarVisible: true,
    sidebarUnfoldable: false,
    theme: '',
    fixedToasts: 0,
    toasts: [],
    appName: process.env.VUE_APP_NAME,
  },
  mutations: {
    toggleAside(state) {
      state.asideVisible = !state.asideVisible;
    },
    toggleSidebar(state) {
      state.sidebarVisible = !state.sidebarVisible;
    },
    toggleTheme(state, payload) {
      state.theme = payload.value;
    },
    toggleUnfoldable(state) {
      state.sidebarUnfoldable = !state.sidebarUnfoldable;
    },
    updateSidebarVisible(state, payload) {
      state.sidebarVisible = payload.value;
    },
    showErrorToast(state, message) {
      let toast: Toast = {message: null, color: null}
      toast.message = message;
      toast.color =  "danger"
      // @ts-ignore
      state.toasts.push(toast)
      state.fixedToasts++
    },
    showSuccessToast(state, message) {
      let toast: Toast = {message: null, color: null}
      toast.message = message;
      toast.color =  "success"
      // @ts-ignore
      state.toasts.push(toast)
      state.fixedToasts++
    },
    showWarningToast(state, message) {
      let toast: Toast = {message: null, color: null}
      toast.message = message;
      toast.color =  "warning"
      // @ts-ignore
      state.toasts.push(toast)
      state.fixedToasts++
    },
  },
  actions: {},
  modules: {
    user: new UserModule,
  }
})
